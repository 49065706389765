import { environment } from "src/environments/environment";
import { IAuditableEntity, IEntityBase } from "../shared/models";

export const ADDRESS_TYPES_ODATA_URL = `${environment.baseOdataUrl}/AddressTypes`;
export const COUNTRY_ODATA_URL = `${environment.baseOdataUrl}/Countries`;
export const COUNTRY_LOCALE_ODATA_URL = `${environment.baseOdataUrl}/CountryLocales`;
export const LANGUAGE_ODATA_URL = `${environment.baseOdataUrl}/Languages`;
export const CONTACT_TYPE_ODATA_URL = `${environment.baseOdataUrl}/ContactTypes`;
export const TENANT_ADDRESS_ODATA_URL = `${environment.baseOdataUrl}/TenantAddresses`;
export const TENANT_CONTACT_ODATA_URL = `${environment.baseOdataUrl}/TenantContacts`;
export const USER_ODATA_URL = `${environment.baseOdataUrl}/Users`;
export const USER_GROUP_ODATA_URL = `${environment.baseOdataUrl}/UserGroups`;
export const USER_STATUS_ODATA_URL = `${environment.baseOdataUrl}/UserStatuses`;
export const ROLE_ODATA_URL = `${environment.baseOdataUrl}/Roles`;
export const ROLE_CONFIGURATION_ODATA_URL = `${environment.baseOdataUrl}/ConfigurationRoles`;
export const ROLE_CONFIGURATION_TYPE_ODATA_URL = `${environment.baseOdataUrl}/RoleConfigurationTypes`;
export const PAGES_ODATA_URL = `${environment.baseOdataUrl}/Pages`;
export const MODULE_ODATA_URL = `${environment.baseOdataUrl}/Modules`;
export const ACCESS_FIELD_ODATA_URL = `${environment.baseOdataUrl}/AccessFields`;
export const ACCESS_TYPE_ODATA_URL = `${environment.baseOdataUrl}/AccessTypes`;
export const USER_GROUP_ASSIGNMENT_ODATA_URL = `${environment.baseOdataUrl}/UserGroupAssignments`;
export const USER_PROFILE_ODATA_URL = `${environment.baseOdataUrl}/UserProfiles`;
export const TITLE_TYPE_ODATA_URL = `${environment.baseOdataUrl}/TitleTypes`;
export const ROLE_ASSIGNMENT_ODATA_URL = `${environment.baseOdataUrl}/RoleAssignments`;
export const POSITION_ODATA_URL = `${environment.baseOdataUrl}/Positions`;
export const DEPARTMENT_ODATA_URL = `${environment.baseOdataUrl}/Departments`;
export const DEPARTMENT_FUNCTION_ODATA_URL = `${environment.baseOdataUrl}/DepartmentFunctions`;
export const LICENSE_PRODUCT_ODATA_URL = `${environment.baseOdataUrl}/LicenseProducts`;
export const LICENSE_ASSIGNMENT_ODATA_URL = `${environment.baseOdataUrl}/LicenseAssignments`;
export const COMPANY_ODATA_URL = `${environment.baseOdataUrl}/Companies`;
export const CURRENCY_ODATA_URL = `${environment.baseOdataUrl}/Currencies`;
export const COMPANY_STATUS_ODATA_URL = `${environment.baseOdataUrl}/CompanyStatuses`;
export const CRM_DEPARTMENT_ODATA_URL = `${environment.baseOdataUrl}/CRMDepartments`;
export const CRM_DEPARTMENT_FUNCTION_ODATA_URL = `${environment.baseOdataUrl}/CRMDepartmentFunctions`;
export const CRM_POSITION_ODATA_URL = `${environment.baseOdataUrl}/CRMPositions`;
export const COMPANY_DEPARTMENT_ODATA_URL = `${environment.baseOdataUrl}/CompanyDepartments`;
export const BANK_ODATA_URL = `${environment.baseOdataUrl}/Banks`;
export const ACCOUNT_TYPE_URL = `${environment.baseOdataUrl}/AccountTypes`;
export const ACCOUNT_STATUS_URL = `${environment.baseOdataUrl}/AccountStatuses`;
export const COMPANY_INDUSTRIES_ODATA_URL = `${environment.baseOdataUrl}/CompanyIndustries`;
export const PRODUCT_INDUSTRY_IMPORT_ODATA_URL = `${environment.baseOdataUrl}/ProductIndustryImports`;
export const State_ODATA_URL = `${environment.baseOdataUrl}/States`;

export interface ITenantAddressFilter
{
    AddressTypeId: number | null,
    City : string,
    State: string,
    Country: string,
}

export interface ITenantAddressesData {
    Id? : number,
    CompanyId : number | any,
    AddressTypeId : number | null,
    AddressLine1 : string,
    AddressLine2 : string,
    Alias: string | null,
    IsDefault: false,
    City : string,
    State: string,
    Country: string,
    ZipCode: string,
    Email: string,
    PhoneNumber: number | null,
    MobileNumber: number | null,
    FaxNumber: number | null,
    Website: string
}

export interface ITenantContactsData {
    Id? : number | null,
    TitleTypeId : number | null,
    DepartmentId: number | null,
    ContactPerson: string,
    IsDefault: false,
    Email: string,
    PositionId: number | null,
    DepartmentFunctionId: number | null
}

export interface ITenantContactNumber {
  Id? : number,
  ContactTypeId : number | null,
  ContactNumber: number | null,
  Extension: string,
  CountryCode: string,
}

export interface ICompanyData {
    Id : number| null,
    Name : string,
    CompanyTypeId : number | any,
    CompanyStatusId : number | any,
    CompanyNumber: string,
    Key : string,
    GstNumber : string,
    BaseCurrencyCode: string
    Director: string,
    RegistrationNumber: string,
    VatNumber: string,
    TaxNumber: string,
    FaxNumber: string,
    Email: string,
    ContactNumber: string,
    IECNumber: string ,
    BaseCountryCode: string,
    BaseLocale: string,
    Description: string,
}

export interface ICompanyDepartmentData{
  Id? : number,
  CRMDepartmentId : number | null,
  ReponsiblePerson: string,
  PhoneNumber: number | null,
  MobileNumber: number | null,
  FaxNumber: number | null,
  Email: string,
}

export interface ICompanyBankAccount{
  Id? : number,
  CompanyId : number | any,
  BankId : number | any,
  AccountTypeId : number | any,
  AccountStatusId : number | any,
  AccountNumber : string,  
  Code : string,  
  BranchName : string,
  BranchNumber : string,
}

export interface IUserFilter{
  UserId: string,
  FirstName: string,
  LastName: string,
  UserStatusId: number |null,
  RoleId: number| null,
  UserGroupId: number| null  
}

export interface IUserData extends IEntityBase,IAuditableEntity {
  Title : number |null,
  Email: string,
  FirstName: string,
  LastName: string,
  MiddleName: string,
  UserStatusId: number |null,
  BaseLanguageCode: string,
  EmployeeCode:string,
  RefreshTimer: number | null
}

export interface IUserGroupFilter  {
  Name: string
}

export interface IUserGroup extends IEntityBase,IAuditableEntity {
  Name: string,
  Description: string,
}

export interface IRoleFilter  {
  Name: string,
}

export interface IRole extends IEntityBase,IAuditableEntity {
  Name: string,
  Description: string,
  IsActive : false,
}

export interface IRoleConfiguration extends IEntityBase,IAuditableEntity {
  RoleConfigurationTypeId : number |null,
}

export interface IRoleAssignment extends IEntityBase,IAuditableEntity {
  AccessFieldId : number |null,
  AccessTypeId : number |null,
  Value : number |null,
}

export interface IUserGroupDetail extends IEntityBase,IAuditableEntity {
  Name: string,
  Description: string
}

export interface IUserGroupAssignment extends IEntityBase,IAuditableEntity {
 // UserGroupId : number |null,
  UserId:number |null
}

export interface ICreateEmployeeData extends IEntityBase,IAuditableEntity {
  EmployeeCode:string,
  DepartmentId: number | null,
  EmployeeTypeId: number | null,
  LeaveGroupId: number | null,
  ManagerId: number | null,
}

export interface ILicenseAssignment extends IEntityBase,IAuditableEntity {
  LicenseProductId: number | null,
  LicenseAssignmentType: number | null,
  AssignmentValueId: number | null,
  AssignedDate: Date | string,
  AssignedById: number | null,
  AvailableLicenses: number
}

export interface ICompanyIndustryData{
  Id? : number | null,
  ProductCategoryId: number | null
}


export interface IProductLicenseMapping {
  LicenseProductId: number;
  LicenseAssignmentType: number;
}


export const productLicenseMappings: IProductLicenseMapping[] = [
  {
    LicenseProductId: 3,
    LicenseAssignmentType: 2 
  },
  {
    LicenseProductId: 4,
    LicenseAssignmentType: 1 
  },
  {
    LicenseProductId: 5,
    LicenseAssignmentType: 1 
  },
  {
    LicenseProductId: 6,
    LicenseAssignmentType: 1 
  },
  {
    LicenseProductId: 7,
    LicenseAssignmentType: 1 
  }

];
