import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { FormActionEvent, FormItem } from 'src/app/shared/components';
import { Properties as SelectBoxProperties, SelectionChangedEvent } from 'devextreme/ui/select_box';
import { Location } from '@angular/common';
import dxCheckBox, { InitializedEvent } from 'devextreme/ui/check_box';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/core/services/translation.service';
import { PopupService } from 'src/app/core/services/popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ValueChangedEvent } from 'devextreme/ui/filter_builder';
import { ADDRESS_TYPES_ODATA_URL, COUNTRY_ODATA_URL, ITenantAddressesData, State_ODATA_URL, TENANT_ADDRESS_ODATA_URL } from 'src/app/admin/admin-model';
import { AdminService } from 'src/app/admin/admin.service';
import { PhoneNumberMaxLength, ValidateFaxNumberRegex, ValidatePhoneNumberRegex, ZipCodeMaxLength } from 'src/app/Utils/constant';
import dxButton, { ClickEvent, Properties as ButtonProperties, InitializedEvent as ButtonInitializedEvent} from 'devextreme/ui/button';
import dxSelectBox, { InitializedEvent  as SelectBoxInitializedEvent,  } from 'devextreme/ui/select_box';
import { disableComponent, enableComponent, hideComponent, showComponent } from 'src/app/Utils/dev-extreme.helper';
import dxTextBox,{ InitializedEvent as TextBoxInitializedEvent }from 'devextreme/ui/text_box';
import { ValidationCallbackData } from 'devextreme/common';

@Component({
  selector: 'app-add-edit-tenant-address',
  templateUrl: './add-edit-tenant-address.component.html',
  styleUrls: ['./add-edit-tenant-address.component.scss']
})
export class AddEditTenantAddressComponent implements OnInit {

  private addressId!:number | any;
  public popupVisibleAddressType!: boolean;
  private addressTypeSelectBox!: dxSelectBox;
  private addAddressTypeButton!: dxButton;
  private countryId!: number | any;
  private zipCodeTextBox!: dxTextBox;
  private cityTextBox!: dxTextBox;
  private stateTextBox!: dxTextBox;
  private stateSelectBox!: dxSelectBox;

  @Input()
  public popupVisibleCompany!:boolean
  @Input()
  public popupVisibleCompanyAddress!:boolean
  @Output()
  public onSave: EventEmitter<number> = new EventEmitter();
  @Output()
  public onCancel: EventEmitter<unknown> = new EventEmitter();

  @Input()
  public companyId:  any;
  private contactId: number | any;

  @Input()
  public companyType: number | any
  

  public tenantAddressesData: ITenantAddressesData = {
    CompanyId : null,
    AddressTypeId : null,
    AddressLine1 : "",
    AddressLine2 : "",
    Alias : "",
    IsDefault: false,
    City : "",
    State: "",
    Country: "",
    ZipCode: "",
    Email: "",
    PhoneNumber: null,
    MobileNumber: null,
    FaxNumber: null,
    Website: ""
  }

  public onValueChange = (e: ValueChangedEvent) => { 
    if(this.tenantAddressesData.Country != ""){
      this.adminService.getDataByCountryZipcode(e.value, this.countryId)
        .then((response: any) => {
          if (response != null) {
            this.tenantAddressesData.City = response.City;
            this.tenantAddressesData.State = response.State;
          }  else {
            return;
          }      
        });
    } else {
      return;
    }    
  }

  public onSelectionChange = (e: SelectionChangedEvent) =>{

    if(!this.isEditedvalue && !this.addressId){
      let filter = e.selectedItem
      ?  ["CountryId", "=", +this.countryId]
      : null;
      let stateDataSource = this.stateSelectBox.getDataSource();
      stateDataSource.filter(filter);
      if(e.selectedItem != null) {
        stateDataSource.load().then((data: Array<any>) =>{
        // this.stateSelectBox.option("value", data ? data[0]?.Name : null);
      });
    }
    }
  }

  public isEditedvalue: boolean = false;

  public tenantAddressesFormItems: Array<FormItem> = [
    {
      itemType: "group",
      caption: this.translationService.translate("dialog_message_address"),
      items: [
        {
          itemType: "group",
          //@ts-ignore
          isAddNewGroup: true,
          items: [
              {
                dataField: "AddressTypeId",
                editorType: "dxSelectBox",
                label: {
                  text: this.translationService.translate("form_address_type")
                },
                validationRules: [
                  { type: "required", message: this.translationService.translate("validation_message_required", this.translationService.translate("form_address_type")) },
                ],
                editorOptions: <SelectBoxProperties> {
                  dataSource: new DataSource({
                    store: new ODataStore({
                      url: ADDRESS_TYPES_ODATA_URL,
                      key: "Id",
                      version: 4
                    }),
                    select: ["Name", "Id"]
                  }),
                  searchEnabled: true,
                  showClearButton: true,
                  searchMode: "contains",
                  displayExpr: "Name",
                  valueExpr: "Id",
                  onInitialized: (e:SelectBoxInitializedEvent) =>{
                    if(e.component) {
                      this.addressTypeSelectBox = e.component;
                    }
                  },
                }
              },
              {
                itemType: "button",
                horizontalAlignment: "right",
                buttonOptions: <ButtonProperties> {
                stylingMode :"text",
                text:  this.translationService.translate("action_add",this.translationService.translate("grid_column_address_type")),
                hoverStateEnabled : false,
                focusStateEnabled: false,
                type: "default",
                onInitialized: (e: ButtonInitializedEvent) =>{
                  if(e.component) {
                    this.addAddressTypeButton = e.component;
                  }
                },
                onClick: (e: ClickEvent) =>{
                  this.popupVisibleAddressType = true;
                }  
                }
              },
            ]
        },
        {
          dataField: "AddressLine1",
          editorType: "dxTextArea",
          editorOptions:{
            showClearButton: true
          },
          label: {
            text: this.translationService.translate("form_address_line_1")
          },
          validationRules: [
            { type: "required", message: this.translationService.translate("validation_message_required", this.translationService.translate("form_address_line_1")) },
          ],
          //@ts-ignore
          autoAlign: false
        },
        {
          dataField: "AddressLine2",
          editorType: "dxTextArea",
          editorOptions:{
            showClearButton: true
          },
          label: {
            text: this.translationService.translate("form_address_line_2")
          },
          //@ts-ignore
          autoAlign: false
        },
        {
          dataField: 'IsDefault',
          editorType: 'dxCheckBox',
          label: {
            text: this.translationService.translate('form_is_default'),
          },
          editorOptions: {
            dataSource: new DataSource({
              store: new ODataStore({
                url: TENANT_ADDRESS_ODATA_URL,
                key: 'Id',
                version: 4,
              }),
              select: ['Name', 'Id'],
            }),
            searchEnabled: true,
            searchMode: 'contains',
            displayExpr: 'Name',
            valueExpr: 'Id',
            onInitialized: (e: InitializedEvent) => {
              if (e.component) {
                this.defaultCheck = e.component;
              }
            },
          },
        },
        {
          dataField: 'Country',
          editorType: 'dxSelectBox',
          label: {
            text: this.translationService.translate('form_country'),
          },
          validationRules: [
            { type: "required", message: this.translationService.translate("validation_message_required", this.translationService.translate("form_country")) },
          ],
          editorOptions: <SelectBoxProperties> {
            dataSource: new DataSource({
              store: new ODataStore({
                url: COUNTRY_ODATA_URL,
                key: 'Id',
                version: 4,
              }),
              select: ['Name', 'Id'],
            }),
            searchEnabled: true,
            showClearButton: true,
            searchMode: 'contains',
            displayExpr: 'Name',
            valueExpr: 'Name',
            onSelectionChanged: (e: SelectionChangedEvent) => {
              if(e.selectedItem != null){
                this.countryId = e.selectedItem.Id;
                enableComponent(this.zipCodeTextBox);
                enableComponent(this.cityTextBox);
                enableComponent(this.stateTextBox);
                enableComponent(this.stateSelectBox);
                this.onSelectionChange(e);
              } else{
                this.tenantAddressesData.ZipCode = "";
                this.tenantAddressesData.City = "";
                this.tenantAddressesData.State = "";
                disableComponent(this.zipCodeTextBox);
                disableComponent(this.cityTextBox);
                disableComponent(this.stateTextBox);
              }         
            }
          },
        },
        {
          dataField: "ZipCode",
          editorOptions: {
            onValueChanged: (e: ValueChangedEvent) =>{
              if(!this.isEditedvalue || (this.isEditedvalue && e.previousValue)) {
                if(e.value != ""){
                  this.onValueChange(e);
                } else {
                  this.tenantAddressesData.City = "";
                  this.tenantAddressesData.State = "";
                }
               }
            },
            onInitialized: (e: TextBoxInitializedEvent) => {
              if (e.component) {
                this.zipCodeTextBox = e.component;
                disableComponent(e.component);
              }
            },
          },
          label: {
            text: this.translationService.translate("form_zipcode")
          },
          validationRules: [
            { type: "stringLength", max: ZipCodeMaxLength, message: this.translationService.translate("validation_string_length", this.translationService.translate("form_zipcode"), ZipCodeMaxLength.toString() ) },
            { type: "required", message: this.translationService.translate("validation_message_required", this.translationService.translate("form_zipcode")) },
          ],  
        },
        {
          dataField: "City",
          editorOptions:{
            showClearButton: true,
            onInitialized: (e: TextBoxInitializedEvent) => {
              if (e.component) {
                this.cityTextBox = e.component;
                disableComponent(e.component);
              }
            },
          },
          label: {
            text: this.translationService.translate("form_city")
          },
          validationRules: [
            { type: "required", message: this.translationService.translate("validation_message_required", this.translationService.translate("form_city")) },
          ]
        },

        {
          dataField: "State",
          editorType: 'dxSelectBox',
          editorOptions: <SelectBoxProperties> {
            dataSource: new DataSource({
              store: new ODataStore({
                url: State_ODATA_URL,
                key: 'Id',
                version: 4,
              }),
              select: ['Name', 'Id'],
            }),
            searchEnabled: true,
            showClearButton: true,
            searchMode: 'contains',
            displayExpr: 'Name',
            valueExpr: 'Name',
            acceptCustomValue: true,
            onCustomItemCreating: (args) => {
              const newValue = args.text;
              args.customItem = { Name: newValue };
            },
            onInitialized: (e: SelectBoxInitializedEvent) => {
              if (e.component) {
                this.stateSelectBox = e.component;
                disableComponent(e.component);
              }
            },
          },
          label: {
            text: this.translationService.translate("form_state")
          },
          validationRules: [
            { type: "custom", validationCallback: (obj: ValidationCallbackData) =>
              {  
                if(this.tenantAddressesData.Country == "India"){
                  return obj.value;
                }
                return true;
              }, message: this.translationService.translate("validation_message_required", this.translationService.translate("form_state")) },
          ]
        },

        {
          dataField: "Email",
          editorOptions: { 
            showClearButton: true,
          },
          label: {
            text: this.translationService.translate("form_email")
          },
          validationRules: [
            { type: "email", message: this.translationService.translate("validation_form_email") },
          ]
        },
        {
          dataField: "PhoneNumber",
          editorType: 'dxTextBox',
          label: {
            text: this.translationService.translate("form_phone_number")
          },
          validationRules: [
            { type: "stringLength", max: PhoneNumberMaxLength, message: this.translationService.translate("validation_string_length", this.translationService.translate("form_phone_number"), PhoneNumberMaxLength.toString() ) },
            { type: "custom", ignoreEmptyValue: true, reevaluate: true, validationCallback: (obj:any) =>{
              return obj.value.match(ValidatePhoneNumberRegex);
            },
            message: this.translationService.translate("validation_number_message", this.translationService.translate("form_phone_number") ) }
          ],
          editorOptions: { showClearButton: true }
        },
        {
          dataField: "MobileNumber",
          editorType: 'dxTextBox',
          label: {
            text: this.translationService.translate("form_mobile_number")
          },
          validationRules: [
            { type: "stringLength", max: PhoneNumberMaxLength, message: this.translationService.translate("validation_string_length", this.translationService.translate("form_mobile_number"), PhoneNumberMaxLength.toString() ) },
            { type: "custom", ignoreEmptyValue: true, reevaluate: true, validationCallback: (obj:any) =>{
              return obj.value.match(ValidatePhoneNumberRegex);
            },
            message: this.translationService.translate("validation_number_message", this.translationService.translate("form_mobile_number") ) }
          ],
          editorOptions: { showClearButton: true }
        },
        {
          dataField: "FaxNumber",
          editorType: 'dxTextBox',
          label: {
            text: this.translationService.translate("form_fax_number")
          },
          validationRules: [
            { type: "custom", ignoreEmptyValue: true, reevaluate: true, validationCallback: (obj:any) =>{
              return obj.value.match(ValidateFaxNumberRegex);
            },
            message: this.translationService.translate("validation_number_message", this.translationService.translate("form_fax_number") ) }
          ],
          editorOptions: { showClearButton: true }
        },
        {
          dataField: "Website",
          editorType: "dxTextArea",
          label: {
            text: this.translationService.translate("form_website")
          },
          editorOptions: { showClearButton: true }
        }
      ],
    },
  ];
   
  private defaultCheck!: dxCheckBox;

  constructor(
    private router: Router,
     private location: Location,
     private route: ActivatedRoute,
     private adminService: AdminService,
     public translationService: TranslationService,
     private popupService: PopupService,
     private toastService: ToastService,
     private spinnerService: SpinnerService) { }

     ngAfterViewChecked(){
      if(this.addressId){
        hideComponent(this.addAddressTypeButton);
      }
      else{
        showComponent(this.addAddressTypeButton);
      }
    }

    companyTypeEnum = new Map([ 
      ["Company",0],
      ["Tenant", 1],
      ["Customer", 2],
      ["Supplier", 3],
      ["Manufacturer", 4],
      ["Lead", 5],
    ]);

  ngOnInit(): void {
    this.route.params.subscribe(params => {
    this.companyId = this.companyId ? this.companyId : +params['id'] ;
    this.tenantAddressesData.CompanyId = this.companyId;
    this.contactId = +params['contactId'];
    this.addressId = +params['addressId'];
    this.companyType = this.companyType ? this.companyType : +params['companyType'];
    if (this.addressId) {
      this.isEditedvalue = true;
      this.getData();
    }
  });
   this.route.queryParamMap.subscribe((params: any) => {
    if(params){
      if(params?.params?.companyType){
        this.companyType = JSON.parse(params.params.companyType);
      }
      
      params?.params?.contactId != null ? this.contactId = JSON.parse(params.params.contactId) : null;
    }
   });
  
}

private addEditCompanyAddress(e: any) {
  if(this.contactId) {
    this.showSpinner();
    return this.adminService.linkContactToTenantAddress(this.contactId, e.data)
    .then((response: any) => {
      this.spinnerService.hideSpinner();
      this.contactId = response.Data[0];
      this.toastService.success(this.translationService.translate("toast_message_added", this.translationService.translate("dialog_message_address")));
      this.popupService.close();
      this.router.navigate([`/lead/contacts`], { queryParams: { companyType: this.companyType } });

    });
} 
  if (this.addressId) {
    this.showSpinner();
    return this.adminService.updateCompanyAddress(this.addressId, e.data)
      .then((response: any) => {
        this.spinnerService.hideSpinner();
        let message = this.translationService.translate("toast_message_updated", this.translationService.translate("dialog_message_address"));
        this.toastService.success(message);

        if (this.companyType == this.companyTypeEnum.get("Customer")) {
          this.router.navigate([`/customer/${this.companyId}/address`], { queryParams: { companyType: this.companyType } });
        } else if (this.companyType == this.companyTypeEnum.get("Supplier")) {
          this.router.navigate([`/supplier/${this.companyId}/address`], { queryParams: { companyType: this.companyType } });
        } else if (this.companyType == this.companyTypeEnum.get("Lead")) {
          this.router.navigate([`/lead/address/add`], { queryParams: { companyType: this.companyType } });
          this.location.back();
        } else if (this.companyType == this.companyTypeEnum.get("Company")) {
        this.router.navigate([`/lead/company/${this.companyId}/address`], { queryParams: { companyType: this.companyType } });
        } else {
          this.router.navigateByUrl("/admin/companyInformation");
          
        }

        this.popupService.close();
        
      });
  } 
  else {
    if (this.companyType == 5) {
      this.showSpinner();
      return this.adminService.addTenantContactwithoutTenantAddress(e.data)
        .then((response: any) => {
          this.spinnerService.hideSpinner();
          this.contactId = response.Data.Id;
          this.toastService.success(this.translationService.translate("toast_message_added", this.translationService.translate("dialog_message_address")));
          this.location.back();
        });
    } 
    
    else {
      this.showSpinner();
      let promise = this.companyId ? this.adminService.addCompanyAddress(this.companyId, e.data) : this.adminService.addAddressWithoutCompany(e.data);
      return promise
        .then((response: any) => {
          this.popupVisibleCompanyAddress = false;
          this.popupVisibleCompany = false;
          this.spinnerService.hideSpinner();
          this.addressId = response.Data.Id;
          let message = this.translationService.translate("toast_message_added", this.translationService.translate("dialog_message_address"));
          this.toastService.success(message);

          if (this.onSave.observers.length > 0) {
            this.onSave?.emit(response.Data.Id);
            e.form.instance.resetValues();
          } else {
            this.router.navigate([`/admin/companyInformation/${this.companyId}/tenantAddresses/${this.addressId}/contact/add`], { queryParams: { companyType: this.companyType } });
          }

          this.popupService.close();
          
        });
    }
  }
 
}


  public onPopupClicked = (event: FormActionEvent) =>{
    let textMessage;
    textMessage = this.translationService.translate('popup_message_default_object_confirmation',this.translationService.translate('grid_column_address'));
    this.popupService.confirm({
      message: textMessage,
      visible: true,
      onNoClicked: () => {
        this.popupService.close();
        this.defaultCheck.reset();
      },
    onYesClicked: (e: any) => {
      this.addEditCompanyAddress(event);
  },
  title: this.translationService.translate('popup_title_default_confirmation'),
  });
};

public onSaveClicked = (e: FormActionEvent) =>{
  if(e.data.IsDefault == true){
    this.onPopupClicked(e);
  }
  else {
   this.addEditCompanyAddress(e);
  }
}

  private showSpinner(){
      this.spinnerService.showSpinner();
  } 

  public onCancelClicked = (e: FormActionEvent) =>{
      if(this.companyType == this.companyTypeEnum.get("Customer") && this.popupVisibleCompanyAddress != true){
        this.router.navigate([`/customer/${this.companyId}/address`],{ queryParams: {companyType: this.companyType }});
      }
      else if(this.companyType == this.companyTypeEnum.get("Supplier")){
        this.router.navigate([`/supplier/${this.companyId}/address`],{ queryParams: {companyType: this.companyType }});
      }
      else if(this.companyType == this.companyTypeEnum.get("Lead")){
        this.router.navigate([`/lead/contacts`],{ queryParams: {companyType: this.companyType }});
      }
      else if (this.companyType == this.companyTypeEnum.get("Company")) {
        this.router.navigate([`/lead/company/${this.companyId}/address`], { queryParams: { companyType: this.companyType } });
      }
      else{
        if (this.onCancel.observers.length > 0) {
          this.onCancel?.emit();
        } else {
          this.router.navigateByUrl("/admin/companyInformation");
        }
      }
  }
  
  public getData(){
       
    this.adminService.getCompanyAddressById(this.addressId)
      .then((response: any) => {
      this.tenantAddressesData = response.Data;
      });
  }
  onaddresstypeSaveClick(AddressTypeId : any ){
    this.popupVisibleAddressType = false;
     let dataSource = this.addressTypeSelectBox.getDataSource();
    dataSource.load();
    this.tenantAddressesData.AddressTypeId = AddressTypeId;  
  }
  onaddresstypeCancelClick(){
    this.popupVisibleAddressType = false;
  }

}
