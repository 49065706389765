import { formatNumber } from 'devextreme/localization';
import * as _ from 'lodash';

export function FormatString(str: string, ...val: string[]) {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}

export function IsNullOrEmtpy(value: string) {
  return value === null || value === undefined || value === '';
}

export function DecimalFormatter(value: any) {
  return isNaN(value) ? value : formatNumber(Number(value), '#0.##');
}

export function ContactNumberFormatString(CountryCode: any, ContactNumber: any, Extension: any) {
  if(CountryCode != null) {
    if (Extension != null && Extension !== "") {
      return `${CountryCode} ${ContactNumber} - ${Extension}`;
    } else {
      return `${CountryCode} ${ContactNumber}`;
    }
  } else {
    if (Extension != null && Extension !== "") {
      return `${ContactNumber} - ${Extension}`;
    } else {
      return `${ContactNumber}`;
    }
  }
 
}

export function VerifyUrl(url: any) {
  if (!url.match(/^https?:\/\//i)) {
    url = 'http://' + url;
}
return window.open(url);
}

export function formatName(firstName: string, middleName: string, lastName: string) {
  return _.filter([firstName, middleName, lastName]).join(' ');
}

export function formatAddress(obj: any){
  return obj ? _.filter([obj.AddressLine1, obj.AddressLine2, obj.City, obj.State, obj.Country, obj.ZipCode]).join(', ') : "";
}