export const  NameMaxLength = 150;
export const  NumberMaxLength = 100;
export const  EmailMaxLength = 200;
export const  PhoneNumberMaxLength = 15;
export const  CountryCodeMaxLength = 10;
export const ZipCodeMaxLength = 10;
export const  DescriptionMaxLength = 250;
export const  AdditionalDescriptionLength = 25000;
export const  ValueMaxLength = 30;
export const  KeyMaxLength = 5;
export const  RemarksMaxLength = 500;
export const SUPPPORT_URL = "https://support.setumes.com/";
export const HoursMinutesRegularExp = /^(?=\d+[hm])(( ?(?!0h)([1]?[\d]|2[0-3])h)?(?!\d))?(( ?(?!0m)[0-5]?[0-9]?m)?(?!\d))?$/;
export const TimePeriodRegularExp = /^(?=\d+[wdhm])(( ?\d+w)?(?!\d))?(( ?\d+d)?(?!\d))?(( ?\d+h)?(?!\d))?(( ?\d+m)?(?!\d))?$/;
export const HourMinuteRegularExp =/^(?=\d+[hm])(( ?(?!0h)([01]?[\d]|2[0-3])h)?(?!\d))?(( ?(?!0m)[0-5]?[0-9]?m)?(?!\d))?$|^24h$/; //for 24h format
export const ValidatePhoneNumberRegex = /^(?!.*[a-zA-Z])[^-](?:[0-9-]\s*){1,20}[0-9]$/;
export const ValidateFaxNumberRegex = /^(?!.*[a-zA-Z])[^-](?:[0-9-]\s*){1,100}[0-9]$/;
export const ValidatePositiveNumberRegex = /^\d+$/;